<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="sendCourseForm"
        ref="sendCourseForm"
        v-model="isFormValid"
        class="custom-form pt-6"
        @submit.prevent="send"
      >
        <v-col cols="12">
          <DateWithTimePicker
            v-if="isDueDateSet"
            :date-with-time="dueDate"
            default-time="05:30"
            label="Data realizacji"
            :keep-time="true"
            @changeTime="dueDate = $event"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="driverId"
            :items="parsedDrivers"
            validate-on-blur
            :rules="[rules.required]"
            label="Przypisz kierowcę"
            placeholder="Wybierz kierowcę"
            outlined
            dense
          >
            <template #item="{ item: driverItem }">
              <div class="w-100 d-flex justify-space-between">
                {{ driverItem.text }}
                <span
                  v-if="driverItem.disabled"
                  class="error--text"
                >({{ driverItem.disabled }})</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="receiverData"
            :items="departmentsList"
            validate-on-blur
            item-text="name"
            :rules="[rules.required]"
            label="Dane przyjmującego"
            placeholder="Wybierz kierowcę"
            outlined
            dense
          />
        </v-col>
        <v-col
          class="py-0 pl-4 mb-0 mt-n3"
          v-if="isKpoRequired"
        >
          <div
            v-if="isKpoCardFulfilled"
            class="success--text"
          >
            {{ kpoSuccessMessage }}
          </div>

          <Warning
            v-else
            :message="kpoLacksMessage"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="sendCourseForm"
      >
        Wyślij kurs
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTimePicker from '../Forms/Inputs/DateWithTime'
import rules from '../../utils/validators'
import driversLacksMixin from '../../mixins/driversLacksList.vue'
import { mapState, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'
import { Course } from '../../models'

const getBdoType = course => course?.order?.client.bdoType

export default {
  components: {
    DialogFormWrapper,
    DateWithTimePicker,
  },
  mixins: [driversLacksMixin], // parsedDrivers
  data () {
    return {
      rules,
      driverId: null,
      dueDate: DateTime.now().toISO(),
      isDueDateSet: false,
      receiverData: '',
      isFormValid: true
    }
  },
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item, // course or route
      departmentsList: state => state.departments.items,
      department: state => state.core.department,
      isProcessing: state => state.course.isProcessing || state.route.isProcessing
    }),
    item () {
      return this.isRoute ? this.dialogItem : new Course(this.dialogItem)
    },
    isRoute () {
      return !!this.dialogItem.stops
    },
    kpoSuccessMessage() {
      const bdoTypes = this.isRoute
        ? [...new Set(this.item.stops.map(stop => getBdoType(stop.course)))]
        : [getBdoType(this.item)]

      if (bdoTypes.length > 1) {
        return 'Wszystkie dokumenty przypięte'
      }

      return bdoTypes.includes('Oświadczenie') ? 'Oświadczenie przypięte' : 'Karta KPO przypięta'
    },
    kpoLacksMessage() {
      const lacks = this.isRoute
        ? [...new Set(this.item.stops.map(stop => getBdoType(stop.course)))]
        : [getBdoType(this.item)]

      if (lacks.length > 1) {
        return lacks.includes('Oświadczenie') ? 'Brak przypiętych dokumentów' : 'Brak przypiętej karty KPO'
      } else {
        return lacks.includes('Oświadczenie') ? 'Brak przypiętego oświadczenia' : 'Brak przypiętej karty KPO'
      }
    },
    isKpoRequired() {
      const checkIfKpoIsRequired = (course) => {
        const isLeaveOrTransportCourse = !course.swapCourse && course.courseType !== 'Zabranie'
        const courseInUse = course.swapCourse || course
        const { bdoType } = courseInUse.order.client || {}
        return !isLeaveOrTransportCourse && ['Wystawia KPO', 'Upoważnienie KPO', 'Oświadczenie'].includes(bdoType)
      }
      return this.isRoute
        ? this.item.stops.some(stop => checkIfKpoIsRequired(stop.course))
        : checkIfKpoIsRequired(this.item)
    },
    isKpoCardFulfilled () {
      const checkCourseKpoCard = (course) => {
        const { bdoCard, bdoFile } = course.swapCourse || course
        return bdoCard?.id || bdoFile?.id
      }
      return this.isRoute
        ? this.item.stops.every(stop => checkCourseKpoCard(stop.course))
        : checkCourseKpoCard(this.item)
    },
  },
  mounted () {
    this.getDrivers({ params: { filters: { departmentId: this.department.id } } })
    const today = DateTime.now().startOf('day')
    const dueDate = DateTime.fromISO(this.item.dueDate).startOf('day')
    if (+dueDate > +today) {
      this.dueDate = dueDate.set({ hours: 5, minutes: 30 }).toISO()
    }
    if (this.item.lastRouteDueDate) {
      this.dueDate = DateTime.fromISO(this.dueDate).plus({ minutes: 5 }).toISO()
    }
    this.isDueDateSet = true
    this.driverId = this.item?.driver?.id
    this.receiverData = this.department.name
  },
  methods: {
    ...mapActions({
      sendCourse: 'course/sendCourse',
      sendRouteToDriver: 'route/sendRouteToDriver',
      getDrivers: 'drivers/getItems',
    }),
    send () {
      if (this.$refs.sendCourseForm.validate()) {
        const { item, driverId, dueDate, receiverData } = this
        const { id, routeId } = this.item
        const params = {
          id: routeId || id, // routeId is for sending route from courses tab
          params: { driverId, dueDate, receiverData }
        }
        item.courseStatus === 'Zaplanowany' || !item.courseStatus ? this.sendRouteToDriver(params) : this.sendCourse(params)
      }
    }
  }
}
</script>
