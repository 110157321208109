import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"sendCourseForm",staticClass:"custom-form pt-6",attrs:{"id":"sendCourseForm"},on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.isDueDateSet)?_c('DateWithTimePicker',{attrs:{"date-with-time":_vm.dueDate,"default-time":"05:30","label":"Data realizacji","keep-time":true},on:{"changeTime":function($event){_vm.dueDate = $event}}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.parsedDrivers,"validate-on-blur":"","rules":[_vm.rules.required],"label":"Przypisz kierowcę","placeholder":"Wybierz kierowcę","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var driverItem = ref.item;
return [_c('div',{staticClass:"w-100 d-flex justify-space-between"},[_vm._v(" "+_vm._s(driverItem.text)+" "),(driverItem.disabled)?_c('span',{staticClass:"error--text"},[_vm._v("("+_vm._s(driverItem.disabled)+")")]):_vm._e()])]}}]),model:{value:(_vm.driverId),callback:function ($$v) {_vm.driverId=$$v},expression:"driverId"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.departmentsList,"validate-on-blur":"","item-text":"name","rules":[_vm.rules.required],"label":"Dane przyjmującego","placeholder":"Wybierz kierowcę","outlined":"","dense":""},model:{value:(_vm.receiverData),callback:function ($$v) {_vm.receiverData=$$v},expression:"receiverData"}})],1),(_vm.isKpoRequired)?_c(VCol,{staticClass:"py-0 pl-4 mb-0 mt-n3"},[(_vm.isKpoCardFulfilled)?_c('div',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.kpoSuccessMessage)+" ")]):_c('Warning',{attrs:{"message":_vm.kpoLacksMessage}})],1):_vm._e()],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"type":"submit","form":"sendCourseForm"}},[_vm._v(" Wyślij kurs ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }